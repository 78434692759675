import React, { Component } from "react";

import { appInsights } from "../../components/AppInsights/AppInsights";
import { DOMParser as parser } from "xmldom";
import TagManager from "react-gtm-module";

import {
  mainPNG,
  identifyPNG,
  fineTunePNG,
  completeEnPNG,
  completeNlPNG,
} from "../../assets/images";
import LocalizedLink from "../../components/LocalizedLink";
import { ArrowIcon, PlayIcon } from "../../assets/icons";
import { bookDemoPath, contactUsPath, pricingPath } from "../../utils/paths";

import { IPageContext, IPrismicHomepage } from "../prismic.model";

import * as styles from "./Home.module.scss";

interface IProps {
  data: IPrismicHomepage;
  pageContext: IPageContext;
}

interface IState {
  isVideoModalOpen: boolean;
}

export default class Home extends Component<IProps, IState> {
  state = {
    isVideoModalOpen: false,
  };

  componentDidMount() {
    appInsights.trackPageView();
  }

  render() {
    const { data } = this.props;
    const iframeString = data.intro_video_for_match_hr.html;

    const iframeDomDocument: Document = new parser().parseFromString(
      `${iframeString}`,
      "text/html"
    );
    const iframeElement = iframeDomDocument.childNodes[0];
    const embedUrl =
      iframeElement.attributes && iframeElement.attributes[2].nodeValue;

    return (
      <div className={styles.container}>
        <div className={styles.introContainer}>
          <div className={styles.introHeadingContainer}>
            <p className={styles.introHeading}>{data.intro_heading.text}</p>
          </div>
          <div className={styles.introImage}>
            <img src={mainPNG} alt="intro" />
          </div>
          <div className={styles.intro}>
            <div className={styles.introContent}>
              <p className={styles.introLabel}>{data.intro_label.text}</p>
            </div>
            <div className={styles.introButtonsContainer}>
              <button
                type="button"
                className={styles.playVideoButtonMobile}
                onClick={this.handleToggleVideoModal}
              >
                <PlayIcon />
              </button>
              <LocalizedLink to={pricingPath}>
                <div className={styles.getStartedButton}>
                  {data.play_video_button.text}
                </div>
              </LocalizedLink>
              <LocalizedLink to={bookDemoPath}>
                <div className={styles.bookDemoButton}>
                  {data.book_demo_button.text}
                </div>
              </LocalizedLink>
              <button
                type="button"
                className={styles.playVideoButtonDesktop}
                onClick={this.handleToggleVideoModal}
              >
                <PlayIcon />
              </button>
            </div>
          </div>
        </div>

        <div className={styles.howItWorks}>
          <p className={styles.howItWorksHeading}>
            {data.how_it_works_heading.text}
          </p>
          <ArrowIcon />
          <div className={styles.howItWorksExplanation}>
            <div className={styles.howItWorksStep}>
              <img src={identifyPNG} alt="identity" />
              <div className={styles.howItWorksStepContent}>
                <div className={styles.howItWorksStepHeading}>
                  <p className={styles.stepNumber}>1</p>
                  <p>{data.how_it_works_step_one_heading.text}</p>
                </div>
                <p className={styles.howItWorksStepLabel}>
                  {data.how_it_works_step_one_label.text}
                </p>
              </div>
            </div>
            <div className={styles.howItWorksStep}>
              <img src={fineTunePNG} alt="fine tune" />
              <div className={styles.howItWorksStepContent}>
                <div className={styles.howItWorksStepHeading}>
                  <p className={styles.stepNumber}>2</p>
                  <p>{data.how_it_works_step_two_heading.text}</p>
                </div>
                <p className={styles.howItWorksStepLabel}>
                  {data.how_it_works_step_two_label.text}
                </p>
              </div>
            </div>
            <div className={styles.howItWorksStep}>
              <img
                src={
                  this.props.pageContext.locale == "en-us"
                    ? completeEnPNG
                    : completeNlPNG
                }
                alt="complete"
              />
              <div className={styles.howItWorksStepContent}>
                <div className={styles.howItWorksStepHeading}>
                  <p className={styles.stepNumber}>3</p>
                  <p>{data.how_it_works_step_three_heading.text}</p>
                </div>
                <p className={styles.howItWorksStepLabel}>
                  {data.how_it_works_step_three_label.text}
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.isVideoModalOpen && (
          <div className={styles.modal}>
            <div
              onClick={this.handleToggleVideoModal}
              className={styles.backDrop}
            />
            <div className={styles.modalContainer}>
              <div className={styles.modalContent}>
                <div className={styles.playVideoModalContentContainer}>
                  <div className={styles.playVideoModalContentContainer}>
                    <iframe src={embedUrl} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  private handleToggleVideoModal = () => {
    this.setState(
      (prevState) => ({
        isVideoModalOpen: !prevState.isVideoModalOpen,
      }),
      () => {
        if (this.state.isVideoModalOpen) {
          const event = {
            dataLayer: {
              event: "ga.introVideo",
              eventProps: {
                category: "User Action",
                action: "Played intro video",
              },
            },
          };

          TagManager.dataLayer(event);
        }
      }
    );
  };
}
